body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

.slide-pane__custom-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
}

.slide-pane__custom-overlay.overlay-after-open {
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.5s;
}

.slide-pane__custom-overlay.overlay-before-close {
    background-color: rgba(0, 0, 0, 0);
}
