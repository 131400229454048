@font-face {
    font-family: 'Telenet';
    src: url('./assets/Telenet-Albra-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Telenet';
    src: url('./assets/Telenet-Albra-Bold.woff') format('woff');
    font-weight: bold;
}
